import http from "./http.service";
import Promisable from "./promisable.service";
import { listActions } from "redux/slices/list";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import ToasterService from "utils/toaster.util";
import DateService from "utils/date.util";
import CampaignService from "./campaign.service";

const url = "/list";

const ListService = {
  getList: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(listActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { list } = success?.data?.data || {};
      dispatch?.(listActions.setList(list));
    } else dispatch?.(listActions.setList({ data: "Not Found" }));

    dispatch?.(listActions.setLoading(false));
    return [success, error];
  },

  getLists: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(listActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { lists, count } = success?.data?.data || {};
      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: lists.map((list: any) => ({
              Name: list.name,
              Campaign: list.campaign?.name,
              "Start Date": DateService.getServerDateString(list.startDate),
              Notes: list.notes,
            })),
          })
        );
      else dispatch?.(listActions.setLists({ lists, count }));
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(listActions.setLoading(false));
    return [success, error];
  },

  getAll: async (data?: any) => {
    const dispatch = getAppDispatch();

    if (data?.all) dispatch?.(modalActions.setLoading(true));
    else dispatch?.(listActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { lists, totalCount } = success?.data?.data || {};
      if (data?.all)
        dispatch?.(
          modalActions.updateData({
            table_data: lists.map((list: any) => ({
              Name: list.name,
              Campaign: list.campaign?.name,
              "Start Date": DateService.getServerDateString(list.startDate),
              Notes: list.notes,
            })),
          })
        );
      else dispatch?.(listActions.setLists({ lists, count: totalCount }));
    }

    if (data?.all) dispatch?.(modalActions.setLoading(false));
    else dispatch?.(listActions.setLoading(false));
    return [success, error];
  },
  getListsOption: async (data?: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(listActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getOption`, data)
    );

    if (success) {
      const { lists } = success?.data?.data || {};

      dispatch?.(listActions.setOptions(lists));
    }

    dispatch?.(listActions.setLoading(false));
    return [success, error];
  },
  addList: async (data: any, campaign?: any, noClose?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );

    if (success) {
      const { list } = success?.data?.data || {};
      if (campaign) CampaignService.getCampaign(campaign);
      dispatch?.(listActions.addList(list));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    dispatch?.(modalActions.setLoading(false));
    if (!noClose) dispatch?.(modalActions.closeModal());

    return [success, error];
  },

  updateList: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(modalActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );
    if (success) {
      const { list } = success?.data?.data || {};
      dispatch?.(listActions.updateList({ id, list }));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  exportAsGoogleSheet: async (id: string) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(listActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/exportAsGoogleSheet`, { listId: id })
    );
    if (success) {
      const { list } = success?.data?.data || {};
      dispatch?.(listActions.refresh());
    }
    dispatch?.(listActions.setLoading(false));

    return [success, error];
  },
  deleteList: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(listActions.deleteListById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default ListService;
